const colors = ["#fcde9c","#faa476","#f0746e","#e34f6f","#dc3977","#b9257a","#7c1d6f"]
const style = require('./map-styles/esri-toner.json')

export function MapConfig() {
  return {
    controls: [
      {
        config: {},
        position: "topleft"
      }
    ],
    map: {
      container: 'map', // container id
      // style: {
      //   "version": 8,
      //   "name": "blank",
      //   "sources": {
      //     "blank": {
      //       "type": "vector",
      //       "url": ""
      //     }
      //   },
      //   "layers": [{
      //     "id": "background",
      //     "type": "background",
      //     "paint": {
      //       "background-color": "#1d1f20"
      //     }
      //   }]
      // },
      style: style, //'mapbox://styles/mapbox/dark-v10', // style URL
      center: [-120.2,37.3], // starting position [lng, lat]
      zoom: 6.5, // starting zoom,
      minZoom: 4,
      hash: true,
      renderWorldCopies: false,
      maxBounds: [[-170.0,-75.06],[180.0, 75.06]]
    },
  }
}