const currentFeature = {
  name: null,
  id: null,
  idField: null
}

/**
 * 
 * @param {Object} map 
 * @param {Object} layer 
 * @param {String} color 
 */
export function addHighlightListener(map, layers) {
  layers.forEach(layer => {
    if (layer.metadata.highlight === "false") {
      console.log("skipping highlight listener for", layer.id)
    } else {
      map.on("mousemove", layer.id, () => {
        map.getCanvas().style.cursor = 'pointer'
      });
      map.on("mouseout", layer.id, () => {
        map.getCanvas().style.cursor = ''
      });
      map.on("click", layer.id, (e) => {
        const features = map.queryRenderedFeatures(e.point);
        const color = layer.metadata && layer.metadata.highlightColor ? layer.metadata.highlightColor : "yellow"
        setHighlight(map, features[0], color)
        // mapInfo(features[0].properties.n_buildings)
      });
    }
  });
  addClearHighlightListener(map, layers)
}

// function mapInfo(html) {
//   const div = document.createElement("div");
//   div.style.position = "absolute";
//   div.style.top = "10px";
//   div.style.color = "white"
//   div.style.padding = "10px";
//   div.style.margin = "10px"
//   div.style.borderRadius = "3px"
//   div.style.backgroundColor = "rgba(0,0,0,0.5)";
//   div.innerText = html;
//   document.body.appendChild(div);

//   document.body.addEventListener("click", () => {
//     document.body.removeChild(div)
//   })
// }

export function addClearHighlightListener(map) {

  map.on("click", (e) => {

    if (!currentFeature.name) return

    const features = map.queryRenderedFeatures(e.point);
    if (!features.length) clearHighlight(map)

    const isHighlightedFeature = features.filter(f => {
      return f.layer.id === currentFeature.name && (f.properties[currentFeature.idField] === currentFeature.id)
    })
    console.log(isHighlightedFeature)
    if (!isHighlightedFeature.length) clearHighlight(map)

  })
}

/**
 * Clear any layers with the word mgl_highlightLayer in the id and reset the currentFeature values
 * @param {Object} map
 */
export function clearHighlight(e) {
  const map = e.point ? e.target : e;
  if (!currentFeature.name) return
  currentFeature.name = null;
  currentFeature.id = null;
  currentFeature.idField = null;
  //GET HIGHLIGHT LAYERS
  const _layers = map.getStyle().layers;
  const highlightLayers = _layers.filter((l) =>
    l.id.includes("mgl_highlightLayer")
  );
  // console.log(highlightLayers);

  //CLEAR HIGHLIGHT LAYERS
  if (highlightLayers.length > 0) {
    highlightLayers.forEach((h) => {
      if (map.getLayer(h.id)) {
        map.setLayoutProperty(h.id, "visibility", "none");
        map.removeLayer(h.id);
      }
    });
  }
}

export function setHighlight(map, feature, color) {

  if (!feature || !feature.layer || !feature.layer.id || !feature.properties) return
  const idFields = ["id", "ID", "FID", "fid", "ogc_fid", "OGC_FID", "OBJECTID", "objectid"];
  const fields = Object.keys(feature.properties);
  const featureIdFields = fields.filter(f => idFields.indexOf(f) > -1)
  const idField = featureIdFields[0]
  if (!idField) {
    console.log("Missing idField!")
    return
  }

  if (feature.properties[idField] != currentFeature.id || currentFeature.name != feature.layer.id) {
    clearHighlight(map)
    currentFeature.name = feature.layer.id;
    currentFeature.id = feature.properties[idField];
    currentFeature.idField = idField
  } else {
    return
  }

  const highlightColor = !color ? "yellow" : color;

  if (!map.getLayer("mgl_highlightLayer_" + feature.layer.id)) {
    map.addLayer({
      id: "mgl_highlightLayer_" + feature.layer.id,
      source: feature.source,
      "source-layer": feature.sourceLayer ? feature.sourceLayer : "",
      type: ["line", "fill"].includes(feature.layer.type) ? "line" : "circle",
      paint: ["line", "fill"].includes(feature.layer.type)
        ? {
          "line-color": highlightColor,
          "line-width": 6,
        }
        : {
          "circle-color": "transparent",
          "circle-radius": feature.layer.paint["circle-radius"],
          "circle-stroke-width": 4,
          "circle-stroke-color": highlightColor,
          "circle-opacity": 1,
        },
      layout: {
        visibility: "none",
      },
    });
  }

  map.setFilter("mgl_highlightLayer_" + feature.layer.id, ["==", ["get", idField], feature.properties[idField]])

  map.setLayoutProperty(
    "mgl_highlightLayer_" + feature.layer.id,
    "visibility",
    "visible"
  );

}
