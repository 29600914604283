// const map = new L.map("app", {
//   preferCanvas: true
// })
// .setView([    32.51728904582773,     -117.30995178222655,], 13)

// var CartoDB_DarkMatter = L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png', {
// 	attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
// 	subdomains: 'abcd',
// 	maxZoom: 20
// }).addTo(map)

// // Convert a lat/lng point to a hexagon index at resolution 7
// const h3Index = h3.geoToH3(37.3615593, -122.0553238, 7);
// // -> '87283472bffffff'

// console.log(h3Index)

// // Get the center of the hexagon
// const hexCenterCoordinates = h3.h3ToGeo(h3Index);
// // -> [37.35171820183272, -122.05032565263946]
// console.log(hexCenterCoordinates)

// // Get the vertices of the hexagon
// const hexBoundary = h3.h3ToGeoBoundary(h3Index);
// // -> [ [37.341099093235684, -122.04156135164334 ], ...]
// console.log(hexBoundary)

// // Get all neighbors within 1 step of the hexagon
// const kRing = h3.kRing(h3Index, 1);
// // -> ['87283472bffffff', '87283472affffff', ...]
// console.log(kRing)

import { addClearHighlightListener, addHighlightListener, clearHighlight, setHighlight } from "./mapbox-gl-higlight.js";

import { MapConfig } from "./config.js";

const config = MapConfig();
const map = new mapboxgl.Map(config.map);

map.on("load", async () => {
  init();
});

function init() {
  const polygon = [
    [-117.30995178222655, 32.51728904582773],
    [-116.85470581054686, 32.51728904582773],
    [-116.85470581054686, 32.87785719403553],
    [-117.30995178222655, 32.87785719403553],
    [-117.30995178222655, 32.51728904582773],
  ];
  polygon.map(i => i.sort().reverse());
  let ids = h3.polygonToCells(polygon, 8);
  let hexagons = [];
  ids.forEach(i => {
    hexagons.push(h3.cellToBoundary(i, true))
  })
  // console.log(hexagons);

  const geojson = {
    type: "FeatureCollection",
    features: [],
  };

  hexagons.forEach((h,i) => {
    geojson.features.push({
      type: "Feature",
      geometry: {
        coordinates: [h],
        type: "Polygon",
      },
      properties: {index: ids[i]},
    });
  });

  // console.log(geojson);

  const source = {
    type: "geojson",
    data: geojson,
    attribution: "Project and Custom Style by <a href='https://www.getbounds.com'>getBounds</a>"
  };

  const fill = {
    id: "grid",
    source: "grid",
    type: "fill",
    paint: {
      "fill-color": "fuchsia",
      "fill-opacity": 0.2
    },
    metadata: {
      popup: true,
      idField: "id"
    }
  }

  const line = {
    id: "grid-line",
    source: "grid",
    type: "line",
    paint: {
      "line-color": "fuchsia",
      "line-width": 1.5
    },
    metadata: {
      popup: false,
      idField: "id"
    }
  }

  // const layer = new L.GeoJSON(geojson, {
  //   onEachFeature: (feature, layer) => {
  //     layer.bindPopup("H3 Index: " + feature.properties.grid + "<br>" + feature.properties.h3);
  //   },
  // }).addTo(map);

  map.addSource("grid", source);
  map.addLayer(fill);
  map.addLayer(line)
  addHighlightListener(map, [fill]);

  const popup = new mapboxgl.Popup({ closeOnClick: false })
  popup.on("close", () => {
    clearHighlight(map)
  })

  map.on("contextmenu", () => {
    popup.remove()
  })

  map.on("click", (e) => {
    const features = map.queryRenderedFeatures(e.point, {"layers": ["grid"]});
    if (features.length) {
      popup
      .setLngLat(e.lngLat)
      .setHTML(`<strong>h3 grid: </strong>${features[0].properties.grid}<br>${features[0].properties.index}`)
      .addTo(map)
    }
  })

  // map.fitBounds(layer.getBounds());
  const geojsonGrids = {};
  let currentIndex = 0;
  // let currentZoom = 0;
  let freeze = -1;

  const gridSelector = document.createElement("button");
  gridSelector.textContent = "Freeze Grid Size";
  gridSelector.id ="grid-selector"
  const style = {
    position: "absolute",
    top: "10px",
    left: "10px",
    borderRadius: "20px",
    padding: "4px 8px",
    cursor: "pointer",
    fontWeight: 300,
    fontSize: "1rem",
    backgroundColor: "#151515",
    color: "white",
    borderStyle: "solid",
    borderColor: "#151515"
  }
  Object.keys(style).map(s => {
    gridSelector.style[s] = style[s]
  })
  gridSelector.onclick = (e) => {
    freeze = freeze * -1;
    if (freeze < 0) {
      e.target.style.backgroundColor = "#151515"
      updateGrid()
    }else{
      e.target.style.backgroundColor = "hsl(300deg 100% 40%)"
      updateGrid()
    }
  }
  document.body.appendChild(gridSelector);

  function updateGrid(force) {
    let index = (freeze < 0) ? Number(((map.getZoom() + 1) * 0.6).toFixed(0)) : currentIndex;

    currentIndex = index > 13 ? 13 : index + 0;
    document.querySelector("#grid-selector").innerText = "Freeze Grid (" + currentIndex + ")"
    // currentZoom = Math.round(map.getZoom())
    console.log("LOG -- calculating new grids")

    const bounds = map.getBounds();
    // console.log(bounds)
    const polygon2 = [[
      [bounds._ne.lng, bounds._ne.lat],
      [bounds._sw.lng, bounds._ne.lat],
      [bounds._sw.lng, bounds._sw.lat],
      [bounds._ne.lng, bounds._sw.lat],
      [bounds._ne.lng, bounds._ne.lat]
    ]];
    // console.log(polygon2)
    const buffered = turf.buffer(turf.polygon(polygon2), 1, {units: 'kilometers'})
    buffered.geometry.coordinates[0].map(v => v.reverse());
    let ids = h3.polygonToCells(buffered.geometry.coordinates, index);
    // console.log(ids)
    let hexagons = [];
    ids.forEach(i => {
      hexagons.push(h3.cellToBoundary(i, true))
    })
    // console.log(hexagons);

    geojson.features = [];
    hexagons.forEach((h,i) => {
      geojson.features.push({
        type: "Feature",
        geometry: {
          coordinates: [h],
          type: "Polygon",
        },
        properties: {
          id: i,
          index: "index " + currentIndex,
          grid: ids[i],
        },
      });
    });
    console.log("LOG -- total features:", geojson.features.length);
    geojsonGrids[index] = geojson;
    
    // layer.clearLayers();
    // layer.addData(geojson);

    map.getSource("grid").setData(geojson)
  }

  updateGrid();

  map.on("zoomend", () => {
    clearHighlight(map);
    popup.remove()
    updateGrid();
  });
  map.on("moveend", () => {
    clearHighlight(map);
    popup.remove()
    updateGrid(true)
  });
}
